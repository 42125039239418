/**
 * Created by r4zi4l on 17.09.2021
 */

var OrdersWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        options = options || {};
        var maker = options.maker || Game.currentGame.orders.findReady() || Game.currentGame.orders.findCanCook() || Game.currentGame.orders.listAvailableMakers()[0];

        this._super({
            name: "OrdersWindow",
            title: "OrdersWindow.title",
            noPadding: true,
            closeButton: true,
            content: this.createContent(maker),
            foreground: bundles.windows.frames.window_foreground_png
        });

        this.icons.forEach(function (icon) {
            icon.updateSelection(maker);
        });

        Game.currentGame.orders.on("orderClaimed", this.onOrderClaimed.bind(this), this);
        Game.currentGame.orders.on("ingredientsConsumed", this.onIngredientsConsumed.bind(this), this);
    },

    getPersonBundles: function () {
        var heroBundles = [];
        cleverapps.unitsLibrary.listAvailableHeroes().forEach(function (hero) {
            var person = cleverapps.persons.getRole(hero.code);
            if (person && person.bundle) {
                heroBundles.push(person.bundle);
            }
        });
        return heroBundles;
    },

    createContent: function (maker) {
        var styles = cleverapps.styles.OrdersWindow;

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(styles);

        var gridScroll = this.createGridScroll(maker);
        gridScroll.setPositionRound(styles.grid);
        content.addChild(gridScroll);

        var details = this.details = new OrderDetails(maker);
        details.setAnchorPoint(0.5, 0.5);
        details.setPositionRound(styles.details);
        content.addChild(details);

        return content;
    },

    createGridScroll: function (maker) {
        var styles = cleverapps.styles.OrdersWindow.grid;
        var grid = this.createGrid();

        var scroll = this.scroll = new cleverapps.UI.ScrollView(grid, {
            scrollBarEnabled: false,
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL
        });
        scroll.setContentSize(styles.scroll.width, styles.scroll.height);

        for (var i = 0; i < this.icons.length; ++i) {
            if (this.icons[i].maker === maker) {
                scroll.runAction(new cc.ScrollAction(this.icons[i], { silent: true }));
                break;
            }
        }

        var background = cleverapps.UI.createScale9Sprite(bundles.orderswindow.frames.orders_background, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize2(styles);

        background.addChild(scroll);
        scroll.setPositionRound(background.width / 2, background.height / 2);

        var foreground = cleverapps.UI.createScale9Sprite(bundles.orderswindow.frames.orders_foreground, cleverapps.UI.Scale9Rect.TwoPixelXY);
        foreground.setContentSize2(background.getContentSize());
        foreground.setPositionRound(background.width / 2, background.height / 2);
        background.addChild(foreground);

        return background;
    },

    createGrid: function () {
        var styles = cleverapps.styles.OrdersWindow.grid;

        var icons = this.icons = Game.currentGame.orders.listAvailableMakers().map(function (maker) {
            return new OrderIcon(maker);
        }, this);

        if (icons.length === 0) {
            var text = cleverapps.UI.generateOnlyText("OrdersWindow.placeholder", cleverapps.styles.FONTS.LIGHT_TEXT);
            text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            text.setDimensions(styles.width * 0.9, 0);
            return text;
        }

        var grid = new cleverapps.GridLayout(icons, {
            columns: 3,
            margin: styles.margin,
            padding: styles.padding
        });

        icons.forEach(function (icon) {
            icon.unitsLayer.replaceParentSamePlace(grid);
        });

        return grid;
    },

    onOrderClaimed: function (maker) {
        this.readyMaker = maker.unit;
        this.close();
    },

    onIngredientsConsumed: function (maker) {
        var ingredients = this.details.ingredients.listIcons();

        var flyToTarget = function (target) {
            var position;
            this.runAction(new cc.Sequence(
                new cc.CallFunc(function () {
                    position = this.convertToNodeSpace(target.parent.convertToWorldSpace(target.getPosition()));

                    ingredients.forEach(function (icon) {
                        var flyingIcon = new IngredientOrderIcon({
                            ingredient: icon.ingredient
                        });
                        flyingIcon.setPositionRound(this.convertToNodeSpace(icon.parent.convertToWorldSpace(icon.getPosition())));
                        flyingIcon.setLocalZOrder(10);
                        this.addChild(flyingIcon);

                        flyingIcon.runAction(new cc.Sequence(
                            new cc.JumpTo(0.8, position.x, position.y, cleverapps.styles.OrderIcon.fly.height, 1),
                            new cc.RemoveSelf()
                        ));
                    }, this);
                }.bind(this)),
                new cc.DelayTime(0.8),
                new cc.PlaySound(bundles.merge.urls.unit_desturction_effect),
                new cc.CallFunc(function () {
                    var animation = new cleverapps.Spine(bundles.orderswindow.jsons.ow_rewards_highlight_json);
                    animation.setScale(1.7);
                    animation.setAnchorPoint(0.5, 0.5);
                    animation.setPositionRound(position.x, position.y);
                    animation.setAnimation(0, "animation", false);
                    animation.setCompleteListenerRemove();
                    this.addChild(animation);
                }.bind(this)),
                new cc.DelayTime(0.1),
                new cc.CallFunc(function () {
                    target.setFreeze(false);
                    target.updateState(target.maker);
                })
            ));
        }.bind(this);

        var target;
        for (var i = 0; i < this.icons.length; ++i) {
            if (this.icons[i].maker === maker) {
                target = this.icons[i];
                target.setFreeze(true);

                this.scroll.runAction(new cc.Sequence(
                    new cc.ScrollAction(target, {
                        duration: 0.5,
                        visibleBox: {
                            top: 0.97,
                            bottom: 0.97
                        }
                    }),
                    new cc.CallFunc(flyToTarget.bind(this, target))
                ));

                break;
            }
        }
    },

    beforeCloseAnimation: function (callback) {
        if (!this.readyMaker) {
            callback();
            return;
        }

        this.hideSelf();

        Map2d.currentMap.getScrollView().runAction(new cc.Sequence(
            new cc.CellScrollAction(this.readyMaker, {
                allowScrollWithFocus: true
            }).easing(cc.easeInOut(2)),
            new cc.CallFunc(function () {
                this.readyMaker.handleClick();
            }.bind(this)),
            new cc.DelayTime(1),
            new cc.CallFunc(callback)
        ));
    },

    listBundles: function () {
        return ["main"];
    }
});

cleverapps.styles.OrdersWindow = {
    width: 1590,
    height: 960,

    grid: {
        x: { align: "left", dx: 65 },
        y: { align: "center", dy: -10 },
        width: 800,
        height: 835,

        scroll: {
            width: 800,
            height: 810
        },

        margin: {
            x: 20,
            y: 20
        },

        padding: {
            x: 30,
            y: 30
        },

        text: {
            x: { align: "center" },
            y: { align: "center" }
        }
    },

    details: {
        x: { align: "right", dx: -60 },
        y: { align: "center", dy: 0 }
    }
};
