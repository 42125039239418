/**
 * Created by r4zi4l on 24.08.2021
 */

var OrderDetails = cc.Node.extend({
    ctor: function (maker) {
        this._super();

        this.maker = maker;
        this.setContentSize2(cleverapps.styles.OrderDetails);

        this.createContent();
        this.setLocalZOrder(-1);

        Game.currentGame.orders.on("makerSelected", this.updateMaker.bind(this), this);
        Game.currentGame.orders.on("orderStateChanged", this.updateOrder.bind(this), this);
        Game.currentGame.harvested.on("onChangeItems", this.updateIngredients.bind(this), this);
    },

    createContent: function () {
        var styles = cleverapps.styles.OrderDetails;

        this.person = this.createPerson();
        this.dialogue = this.createDialogue();

        var table = this.table = cleverapps.UI.createScale9Sprite(bundles.orderswindow.frames.details_table, cleverapps.UI.Scale9Rect.Default);
        table.setContentSize2(styles.table);
        table.setPositionRound(styles.table);
        table.setLocalZOrder(1);
        this.addChild(table);

        if (this.maker) {
            this.timer = this.createTimer();

            this.ingredients = new IngredientsList(this.maker);
            table.addChild(this.ingredients);
        }

        this.button = this.createButton();
    },

    createPerson: function () {
        var styles = cleverapps.styles.OrderDetails.person;

        var role = this.maker && this.maker.unit.code || "dwarf";

        var person = new Person(role);
        cleverapps.UI.fitToBox(person, {
            width: styles.width,
            height: styles.height
        });

        this.addChild(person);
        person.setPositionRound(styles);
        person.originalPosition = person.getPosition();

        return person;
    },

    createDialogue: function () {
        var styles = cleverapps.styles.OrderDetails.dialogue;

        var title;
        if (this.maker) {
            title = cleverapps.UI.generateOnlyText("Rewards.list", cleverapps.styles.FONTS.ORDERDETAILS_DIALOGUE_TEXT);
            title.fitTo(styles.content.width);

            var icons = this.maker.recipe.prize.map(function (unit) {
                var icon = UnitView.getUnitImage(unit);
                icon.setAnchorPoint(0.5, 0);
                return icon;
            });

            var rewards = new cleverapps.Layout(icons, {
                direction: cleverapps.UI.HORIZONTAL
            });
            cleverapps.UI.fitToBox(rewards, {
                width: styles.content.width,
                maxScale: 1.2
            });
        } else {
            title = cleverapps.UI.generateOnlyText("OrdersWindow.placeholder", cleverapps.styles.FONTS.ORDERDETAILS_DIALOGUE_PLACEHOLDER_TEXT);
            title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            title.setDimensions(styles.content.width, 0);
            title.fitTo(undefined, styles.content.height);
        }

        var content = new cleverapps.Layout([title, rewards].filter(Boolean), {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.content.margin
        });

        var background = cleverapps.UI.createScale9Sprite(bundles.dialogues.frames.bg_minimal_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize2(content.width * content.scaleX + styles.padding.left + styles.padding.right, content.height * content.scaleY + styles.padding.top + styles.padding.bottom);
        background.setAnchorPoint(0.5, 0.5);
        background.setPositionRound(styles);
        this.addChild(background);

        content.setAnchorPoint(0, 0);
        content.setPositionRound(styles.padding.left, styles.padding.bottom);
        background.addChild(content);

        return background;
    },

    createTimer: function () {
        var styles = cleverapps.styles.OrderDetails.timer;

        if (!this.maker) {
            return new cc.Node();
        }

        var countdown;
        if (this.maker.state === MakesOrder.STATE_COOKING) {
            countdown = new cleverapps.CountDown(this.maker.getTimeLeft());
        } else if (this.maker.state === MakesOrder.STATE_RECIPE) {
            countdown = cleverapps.parseInterval(this.maker.recipe.time);
        } else {
            countdown = 0;
        }

        var background = cleverapps.UI.createScale9Sprite(bundles.orderswindow.frames.details_timer, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize2(styles);
        background.setAnchorPoint(0.5, 0.5);
        background.setPositionRound(styles);
        this.table.addChild(background);

        var text = new cleverapps.CountDownView(countdown, {
            font: cleverapps.styles.FONTS.ORDERDETAILS_TIMER_TEXT
        });
        text.setAnchorPoint(0.5, 0.5);
        text.setPositionRound(styles.text);
        background.addChild(text);

        return background;
    },

    createButton: function () {
        var styles = cleverapps.styles.OrderDetails.button;

        var button;
        if (!this.maker || this.maker.state === MakesOrder.STATE_EMPTY) {
            button = new cleverapps.UI.Button({
                width: styles.width,
                height: styles.height,
                text: "OrdersWindow.button.make",
                disabled: true,
                onClicked: function () {}
            });
        } else if (this.maker.state === MakesOrder.STATE_RECIPE) {
            button = new cleverapps.UI.Button({
                width: styles.width,
                height: styles.height,
                text: this.maker.canCook() ? "OrdersWindow.button.make" : "OrdersWindow.button.buyingredients",
                onClicked: this.onButtonClick.bind(this)
            });
        } else if (this.maker.state === MakesOrder.STATE_READY) {
            button = new cleverapps.UI.Button({
                width: styles.width,
                height: styles.height,
                text: "Claim",
                onClicked: this.onButtonClick.bind(this)
            });
        } else if (this.maker.state === MakesOrder.STATE_COOKING) {
            var buttonOptions = {
                canCoins: true,
                price: SpeedUpWindow.CalcPrice(this.maker.getTimeLeft()),
                freeIcon: TextWithIcon.ICONS_BY_NAME.speedup,
                onClicked: this.onButtonClick.bind(this),
                eventName: cleverapps.EVENTS.SPENT.ORDER,
                speedUp: true,
                type: cleverapps.styles.UI.Button.Images.button_blue,
                width: styles.width,
                height: styles.height
            };

            if (this.maker.recipe && this.maker.recipe.time && ConfirmSpeedUpWindow.isAvailable(buttonOptions.price)) {
                buttonOptions.confirmWindowOptions = {
                    timeLeft: this.maker.getTimeLeft(),
                    totalDuration: cleverapps.parseInterval(this.maker.recipe.time)
                };
            }

            button = new UseGoldButton(buttonOptions);
        }

        button.setPositionRound(styles);
        this.table.addChild(button);

        if (Game.currentGame.tutorial.isActive() || this.maker && this.maker.state === MakesOrder.STATE_READY) {
            button.runAction(new cc.Sequence(
                new cc.DelayTime(Game.currentGame.tutorial.isActive() ? 0.3 : 2.5),
                new cc.CallFunc(function () {
                    FingerView.hintClick(button, {
                        delay: 2.5,
                        repeatDelay: 2.5
                    });
                })
            ));
        }

        return button;
    },

    getHidePosition: function () {
        var originalPosition = this.person.originalPosition;
        return cc.p(originalPosition.x + cleverapps.styles.OrderDetails.person.hidedX, originalPosition.y);
    },

    showPerson: function () {
        this.person.stopAllActions();
        this.person.setPositionRound(this.getHidePosition(this.person));
        this.person.setVisible(false);
        this.person.runAction(new cc.Sequence(
            new cc.DelayTime(0.35),
            new cc.Show(),
            new cc.MoveTo(0.3, this.person.originalPosition).easing(cc.easeBackOut())
        ));
    },

    hidePerson: function () {
        this.person.stopAllActions();

        this.person.runAction(new cc.Sequence(
            new cc.MoveTo(0.3, this.getHidePosition(this.person)).easing(cc.easeBackIn()),
            new cc.RemoveSelf()
        ));
    },

    changePerson: function () {
        this.person.stopAllActions();
        this.hidePerson();
        this.person = this.createPerson(this.maker);
        this.showPerson();
    },

    changeDialogue: function () {
        this.dialogue.stopAllActions();
        this.dialogue.runAction(new cc.Sequence(
            new cc.ScaleTo(0.15, 0.1),
            new cc.RemoveSelf()
        ));
        this.dialogue = this.createDialogue(this.maker);
        this.dialogue.setVisible(false);
        this.dialogue.runAction(new cc.Sequence(
            new cc.DelayTime(0.6),
            new cc.ScaleTo(0, 0.1),
            new cc.Show(),
            new cc.ScaleTo(0.15, 1)
        ));
    },

    changeButton: function () {
        this.button.stopAllActions();
        this.button.disable();
        this.button.runAction(new cc.Sequence(
            new cc.ScaleTo(0.15, 0.1),
            new cc.RemoveSelf()
        ));
        this.button = this.createButton(this.maker);
        this.button.setVisible(false);
        this.button.setScale(0.1);
        this.button.runAction(new cc.Sequence(
            new cc.DelayTime(0.15),
            new cc.Show(),
            new cc.ScaleTo(0.15, 1)
        ));
    },

    changeTimer: function () {
        if (this.timer) {
            this.timer.removeFromParent();
            this.timer = undefined;
        }

        if (this.maker) {
            this.timer = this.createTimer(this.maker);
        }
    },

    changeIngredients: function () {
        if (this.ingredients) {
            this.ingredients.removeAnimated();
            this.ingredients = undefined;
        }

        if (this.maker) {
            this.ingredients = new IngredientsList(this.maker);
            this.table.addChild(this.ingredients);
            this.ingredients.showAnimated();
        }
    },

    updateMaker: function (maker) {
        if (maker === this.maker) {
            return;
        }
        this.maker = maker;

        this.changePerson();
        this.changeButton();
        this.changeDialogue();
        this.changeTimer();
        this.changeIngredients();
    },

    updateOrder: function (maker) {
        if (this.maker === maker) {
            this.changeButton();
            this.changeTimer();
        }
    },

    updateIngredients: function () {
        var canCook = this.maker && this.maker.canCook();
        if (this.canCook !== canCook) {
            this.canCook = canCook;
            this.changeButton();
        }

        if (this.ingredients) {
            this.ingredients.updateAmounts();
        }
    },

    onButtonClick: function () {
        Game.currentGame.orders.processOrder(this.maker);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ORDERDETAILS_TIMER_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    ORDERDETAILS_DIALOGUE_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        stroke: undefined,
        shadow: undefined
    },

    ORDERDETAILS_DIALOGUE_PLACEHOLDER_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        stroke: undefined,
        shadow: undefined
    }
});

cleverapps.styles.OrderDetails = {
    width: 640,
    height: 900,

    table: {
        width: 640,
        height: 410,
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 0 }
    },

    person: {
        hidedX: -450,
        x: { align: "center", dx: -140 },
        y: { align: "bottom", dy: 120 },

        width: 400,
        height: 650
    },

    dialogue: {
        x: { align: "left", dx: 260 },
        y: { align: "bottom", dy: 585 },

        padding: {
            left: 60,
            right: 33,
            top: 20,
            bottom: 26
        },

        content: {
            width: 280,
            height: 250,
            margin: 5
        }
    },

    timer: {
        x: { align: "center", dx: 160 },
        y: { align: "center", dy: 236 },
        width: 290,
        height: 100,

        text: {
            x: { align: "center", dx: -2 },
            y: { align: "center", dy: 9 }
        }
    },

    button: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -96 },
        width: 260,
        height: 86,
        padding: {
            left: 24,
            right: 24,
            top: 5,
            bottom: 5
        },
        margin: 4
    }
};
